export const projetoInforms = [{
    id: 1,
    nameProject: "Meu primeiro portfólio",
    color1: "lightpink",
    color2: "pink",
    image: "./images/ismaelsitesimple.png",
    hrf: "https://simples.ismaeljose.com"
},{
    id: 2,
    nameProject: "Batevendas.com",
    color1: "green",
    color2: "rgb(0, 214, 0)",
    image: "./images/BateVendas.png",
    hrf: "https://batevendas.com"
}];