import './css/footer.css';

function Footer() {

  return (
    <main id="Footer">
    <div>
      <img className="footerImage" src='./images/footer.png'/>
    </div>
    </main>
  );
}

export default Footer;
