import "./css/Contato.css";
import React, { useEffect, useState } from "react";

function Contato() {
  const [isActive, setIsActive] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [buttonCss, setButtonCss] = useState("contact-btn");
  const [trintaSegundos, setTrintaSegundos] = useState(0);
  const [counterS, setCounterS] = useState(0);
  const [htmlCounter, setCounterHtml] = useState(``);

  const mailer = async (nome: string, email: string, mensagem: string) => {
    const fetcher = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nome: nome,
        email: email,
        mensagem: mensagem,
      }),
    };
    fetch(
      "http://br-1-1-sec1-f1f70ec40aaa556905d4a030501c0ba4.ismaeljose.site/mail",
      fetcher
    )
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const dataAtual = new Date().getTime();
    if (isActive == true) {
      const trintaSegundoCalc = dataAtual + 31000;
      if (trintaSegundos == 0 || dataAtual >= trintaSegundoCalc) {
        setTrintaSegundos(trintaSegundoCalc);
        setButtonCss("contact-btn-block");
        mailer(name, email, message);
      }
      if (counterS >= 0) {
        setTimeout(() => {
          setCounterS(counterS - 1);
          if (counterS == 1) {
            setCounterHtml(`Aguarde ${counterS}s para enviar um novo e-mail...`);
          } else {
            setCounterHtml(`Aguarde ${counterS}s para enviar um novo e-mail...`);
          }
        }, 1000);
      } else {
        setTrintaSegundos(0);
        setButtonCss("contact-btn");
        setCounterS(0);
        setCounterHtml(``);
        setIsActive(false);
      }
    }
  }, [counterS, isActive]);

  return (
    <>
      <main id="Contact">
        <div className="contact">
          <table>
            <h1 className="contact-title">Contato</h1>
            <h2 className="contact-alert">{htmlCounter}</h2>
            <tr className="contact-text">
              <td>Nome:</td>
            </tr>
            <tr>
              <td>
                <input
                  className="contact-inputs"
                  placeholder="Nome da pessoa..."
                  onChange={(e) => {
                    return setName(e.target.value);
                  }}
                ></input>
              </td>
            </tr>
            <tr className="contact-text">
              <td>E-mail:</td>
            </tr>
            <tr>
              <td>
                <input
                  className="contact-inputs"
                  placeholder="E-mail de destino..."
                  onChange={(e) => {
                    return setEmail(e.target.value);
                  }}
                ></input>
              </td>
            </tr>
            <tr className="contact-text" style={{ textAlign: "center" }}>
              <td>Conteúdo:</td>
            </tr>
            <tr>
              <td>
                <textarea
                  className="contact-conteudo"
                  placeholder="Escreva algo aqui..."
                  onChange={(e) => {
                    return setMessage(e.target.value);
                  }}
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>
                <button
                  className={buttonCss}
                  onClick={() => [setIsActive(true), setCounterS(30)]}
                  disabled={isActive}
                >
                  <span>Enviar</span>
                </button>
              </td>
            </tr>
          </table>
        </div>
      </main>
    </>
  );
}

export default Contato;
