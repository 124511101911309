export const resumoDados1 = [{
    id: 1,
    nameLanguages: "HTML",
    star1: "★",
    star2: "★",
    star3: "★",
    star4: "☆",
    star5: "☆"
},{
    id: 2,
    nameLanguages: "CSS",
    star1: "★",
    star2: "★",
    star3: "★",
    star4: "☆",
    star5: "☆"
},{
    id: 3,
    nameLanguages: "Java",
    star1: "★",
    star2: "★",
    star3: "★",
    star4: "☆",
    star5: "☆"
},{
    id: 4,
    nameLanguages: "JavaScript",
    star1: "★",
    star2: "★",
    star3: "★",
    star4: "☆",
    star5: "☆"
},{
    id: 5,
    nameLanguages: "TypeScript",
    star1: "★",
    star2: "★",
    star3: "★",
    star4: "☆",
    star5: "☆"
}];