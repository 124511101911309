export const resumoDados3 = [{
    id: 1,
    nameBancos: "MySql",
    star1: "★",
    star2: "★",
    star3: "★",
    star4: "☆",
    star5: "☆"
},{
    id: 2,
    nameBancos: "PostgreSQL",
    star1: "★",
    star2: "★",
    star3: "★",
    star4: "☆",
    star5: "☆"
},{
    id: 3,
    nameBancos: "MongoDB",
    star1: "★",
    star2: "★",
    star3: "☆",
    star4: "☆",
    star5: "☆"
}];