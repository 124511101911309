import { useEffect, useRef, useState } from 'react';
import { motion } from "framer-motion"
import { projetoInforms } from '../../models/projeto';
import './css/Projeto.css';

function Projeto() {
  const carrossel = useRef<HTMLDivElement>(null);
  const [widthRightCarrossel, setWidthRightCarrossel] = useState(0);

  useEffect(() => {
    const scrollWidthRight = carrossel.current?.scrollWidth;
    const offsetHeightRight = carrossel.current?.offsetHeight;

    setWidthRightCarrossel((scrollWidthRight ? scrollWidthRight : 0) - (offsetHeightRight ? offsetHeightRight : 0))
  }, [])

  return (
    <main id="Projects" style={{"overflow": "hidden"}}>
    <div>
    <h1 className="projeto-title" style={{"color": "white"}}>Projetos</h1>
    </div>
    <motion.div className="projeto-item" drag="x" dragConstraints={{right: 0, left: -widthRightCarrossel}} initial={{x: 100}} animate={{x: 0, transition: {delay: 0.1}}}>
      {projetoInforms.map((projeto) => (
        <motion.div key={projeto.id} ref={carrossel}>
          <h2 className="projeto-item-title">{projeto.nameProject}</h2>
          <img src={projeto.image} alt={projeto.nameProject}></img>
        </motion.div>
      ))}
    </motion.div>
    </main>
  );
}

export default Projeto;
